.App {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* background-color: #688063; */
  background-color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

body * {
  font-family: 'Montserrat', sans-serif;
}

iframe {
  width: 100%;
  height: 350px;
}

.carousel .control-dots {
  position: relative;
}

.carousel .control-dots .dot {
  background-color: #688063;
}

@media only screen and (max-width: 350px) {
  div.MuiListItemIcon-root {
    min-width: 26px;
  }
}
